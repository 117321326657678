/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import api from '../api';  // Axios or other API configuration
import handleErrors from '../auth/handleErrors';
import axios from 'axios';


//const apiUrl = process.env.REACT_APP_AUTHOR_URL;
//if (!apiUrl) {
//  throw new Error('REACT_APP_AUTHOR_URL is not defined in the environment variables');
//}
const apiUrl = ""; // don't need this!

const withAuthCheck = (WrappedComponent) => {
  return (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      const checkLoginStatus = async () => {
        try {
          //const response = await axios.get(`${apiUrl}/auth_check`);
          const response = await axios.get(`${apiUrl}/api/auth_check`);
          console.log('auth_check response:', response.data);  // Log the response
          if (response.data.isAuthenticated) {
            setIsAuthenticated(true);  // User is authenticated, proceed to render component
            //navigate('/dashboard');  // Redirect to dashboard if authenticated
          } else {
            setIsAuthenticated(false);
            navigate('/login');  // Redirect to login if not authenticated
          }
        } catch (error) {
          console.log('auth_check gave error', error)
          setIsAuthenticated(false);
          navigate("/login")
          // Call handleErrors with a retry callback of null because no specific retry is needed here
          // await handleErrors(error, navigate, null, setErrorMessage);
        }
      };

      checkLoginStatus();  // Perform login status check on component mount
    }, [navigate]);

    // Return "Loading..." until authentication is checked
    if (isAuthenticated === null) {
      return <div>Loading...</div>;
    }

    return isAuthenticated ? (
      <WrappedComponent {...props} />
    ) : (
      <div>
        {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : <p>Loading...</p>}
      </div>
    );
  };
};

export default withAuthCheck;
