export function isWhitelisted(ip) {
    const MANOJ_IP = '100.8.210.61'; // Replace this with your IP address
    const MANOJ_INDIA_IP = '183.';
    const NIKITA_IP = '152.58.'; // '152.58.68.22'
    const NIKITA_IP2 = '49.43.'; // '49.43.160.107'
    const SHUBHAM_IP = '27.59.'; // 27.59.76.189
    const TANISHA_IP = '174.204.130.117'; // Replace this with your IP address
    const ALLOWED_IPS = [MANOJ_IP, SHUBHAM_IP, NIKITA_IP, NIKITA_IP2, MANOJ_INDIA_IP, TANISHA_IP];

    // Check if the IP is an exact match or starts with any allowed prefix
    return ALLOWED_IPS.some(allowedIp => ip === allowedIp || ip.startsWith(allowedIp));
}