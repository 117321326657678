/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 *
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized
 * access, use, or distribution outside the official repositories of AIPolls.Net is
 * strictly prohibited.
 *
 * The founding team and any future employees or contractors are bound by these rules.
 *
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api"; // Assuming Axios instance is set up
import withAuthCheck from "../hoc/withAuthCheck";
import Sidebar from "../utils/Sidebar.js";
import handleErrors from "../auth/handleErrors";
import logoutIcon from "../logout_icon.png";
import profileIcon from "../profile_icon.png";

const ListResearchPolls = () => {
  const [researchPolls, setResearchPolls] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [openPollId, setOpenPollId] = useState(null);
  const [marketerId, setMarketerId] = useState(null);
  const [customMarketerId, setCustomMarketerId] = useState("");

  // Logout handler
  const handleLogout = async () => {
    try {
      await api.post("/logout"); // Logout API
      localStorage.removeItem("access_token"); // Remove access token
      navigate("/login"); // Redirect to login
    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  // Fetch research polls
  useEffect(() => {
    const fetchResearchPolls = async () => {
      try {
        setErrorMessage("");
        const response = await api.get("/research_polls"); // Fetch research polls
        setResearchPolls(response.data.polls); // Set the polls in state
        setMarketerId(response.data.marketer_id);
        setErrorMessage("");
      } catch (error) {
        handleErrors(error, navigate, fetchResearchPolls, setErrorMessage);
      }
    };

    fetchResearchPolls(); // Call on component mount
  }, [navigate]);

  const handleDeletePoll = async (pollId) => {
    try {
      await api.delete(`/delete_research_poll/${pollId}`); // Delete the poll by ID
      setResearchPolls((prevPolls) =>
        prevPolls.filter((poll) => poll.poll_id !== pollId)
      ); // Remove the deleted poll from the list
      setErrorMessage("");
      setSuccessMessage("Poll deleted successfully.");
    } catch (error) {
      setSuccessMessage("");
      handleErrors(error, navigate, handleDeletePoll, setErrorMessage);
    }
  };

  const handleOpenPoll = (pollId) => {
    setOpenPollId(pollId); // Set the poll being opened
    setCustomMarketerId(marketerId); // Prepopulate marketerId if available
  };

  const handleGoToPoll = () => {
    if (customMarketerId) {
      window.open(
        `/openResearchPoll/${openPollId}/${customMarketerId}`,
        "_blank"
      ); // Open the poll with marketerId as a query parameter
      setOpenPollId(null); // Reset after opening
      setCustomMarketerId(""); // Clear marketerId after use
    } else {
      setErrorMessage("Please enter a marketer ID.");
    }
  };

  const handleViewPoll = (pollId) => {
    console.log(`Viewing research poll with ID: ${pollId}`);
    navigate(`/viewResearchPoll/${pollId}`); // Navigate to view the poll
  };

  const handleCancel = () => {
    navigate("/dashboard"); // Go back to dashboard
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">AIPolls.Net Research Polls</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img
                alt="Logout"
                title="Logout"
                src={logoutIcon}
                style={{ width: "25px", height: "25px" }}
              />
            </button>
            <button onClick={handleProfile}>
              <img
                alt="Profile"
                title="Profile"
                src={profileIcon}
                style={{ width: "25px", height: "25px" }}
              />
            </button>
          </div>
        </div>

        <h2>Your Research Polls</h2>

        {errorMessage && <p style={{ color: "black" }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: "black" }}>{successMessage}</p>}

        {researchPolls.length === 0 ? (
          <p>
            No research polls available. Create a Research Poll to get started.
          </p>
        ) : (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {researchPolls.map((poll) => (
              <li key={poll.poll_id} style={{ marginBottom: "20px" }}>
                {poll.title && (
                  <p style={{ color: "black" }}>Title: {poll.title}</p>
                )}
                {poll.description && (
                  <p style={{ color: "black" }}>
                    Description: {poll.description}
                  </p>
                )}
                <p style={{ color: "black" }}>Poll ID: {poll.poll_id}</p>
                <p style={{ color: "black" }}>
                  Expiry: {new Date(poll.expiry).toLocaleString()}
                </p>
                <p style={{ color: "black" }}>
                  Created At:{" "}
                  {new Date(poll.created_at).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                <strong style={{ color: "black" }}>
                  Question: {poll.question}
                </strong>
                <div>
                  <p style={{ fontWeight: "bold", color: "black" }}>Options:</p>{" "}
                  {/* Options label */}
                  {poll.options && poll.options.length > 0 && (
                    <ul
                      style={{
                        listStyleType: "circle",
                        marginLeft: "20px",
                        color: "black",
                      }}
                    >
                      {poll.options.map((option, index) => (
                        <li
                          key={`${poll.poll_id}-option-${index}`}
                          style={{ margin: "5px 0", color: "black" }}
                        >
                          {option.choice_text}{" "}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {openPollId === poll.poll_id ? (
                  // Show marketerId input and Go button if this poll is being opened
                  <div>
                    <label style={{ marginRight: "10px", color: "black" }}>
                      Marketer ID:
                    </label>
                    <input
                      type="text"
                      value={customMarketerId}
                      onChange={(e) => setCustomMarketerId(e.target.value)}
                      placeholder="Enter Marketer ID"
                      style={{ marginRight: "10px" }}
                    />
                    <button onClick={handleGoToPoll}>Go</button>
                  </div>
                ) : (
                  // Show Open Poll button if no poll is currently being opened
                  <button onClick={() => handleOpenPoll(poll.poll_id)}>
                    Open Poll
                  </button>
                )}
                <button
                  onClick={() => handleDeletePoll(poll.poll_id)}
                  style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    fontSize: "16px",
                    color: "#fff",
                    backgroundColor: "#FF0000", // Red for delete button
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  Delete Poll
                </button>
                <button onClick={() => handleViewPoll(poll.poll_id)}>
                  View Poll
                </button>
              </li>
            ))}
          </ul>
        )}

        <button onClick={handleCancel} style={{ marginTop: "20px" }}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default withAuthCheck(ListResearchPolls);
