/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 *
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized
 * access, use, or distribution outside the official repositories of AIPolls.Net is
 * strictly prohibited.
 *
 * The founding team and any future employees or contractors are bound by these rules.
 *
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api";
import withAuthCheck from "../hoc/withAuthCheck"; // Assuming withAuthCheck HOC is implemented
import { refreshToken } from "../auth/auth.js"; // Assuming refreshToken function is implemented
import handleErrors from "../auth/handleErrors.js";

const AdvanceOpinionGroupPoll = () => {
  const { poll_session_id } = useParams(); // Get poll_id from URL
  const [qrCodeUrl, setQrCodeUrl] = useState(""); // Store QR code URL after poll is advanced
  const [errorMessage, setErrorMessage] = useState("");
  const [pollSessionId, setPollSessionId] = useState(poll_session_id);
  const [localIP, setLocalIP] = useState("  "); // Set the local IP address
  const [message, setMessage] = useState("");
  const [realTime, setRealTime] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to advance the poll
    const advancePoll = async () => {
      try {
        const response = await api.get(
          `/advance_opinion_group_poll/${poll_session_id}`
        ); // Call the advance poll API
        const qrCodeURL = response.data.qr_code_image; // Extract QR code image
        setQrCodeUrl(qrCodeURL); // Set the state with the QR code URL
        setPollSessionId(response.data.poll_session_id); // Set the poll session ID
        setLocalIP(response.data.localIP); // Set the local IP address
        setErrorMessage("");
        setRealTime(response.data.realTime);
        setMessage(response.data.message);
      } catch (error) {
        handleErrors(error, navigate, refreshToken, setErrorMessage);
      }
    };

    advancePoll();
  }, [poll_session_id, navigate]); // Re-run the effect whenever poll_id or navigate changes

  return (
    <div>
      {errorMessage ? (
        // Display the error message if there is one
        <div style={{ color: "red" }}>
          <h2>Error:</h2>
          <p>{errorMessage}</p>
        </div>
      ) : (
        // Display the QR code and success message if no error
        <>
          <h2>{message}</h2>
          {pollSessionId &&
            !message.includes("All polls completed. Campaign closed.") && (
              <p>
                Share the poll with participants using the generated QR code.
              </p>
            )}
          {/*<p>Session Id: {pollSessionId}</p> */}
          {/* Add a button that opens a link in a new tab */}
          {pollSessionId && realTime && (
            <a
              href={`http://${localIP}/advanceOpinionGroupPoll/${pollSessionId}`}
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                padding: "10px 20px",
                fontSize: "16px",
                color: "#fff",
                backgroundColor: "#007BFF", // Bootstrap primary color
                border: "none",
                borderRadius: "5px",
                textDecoration: "none",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              Advance To Next Opinion Group Poll
            </a>
          )}
          {qrCodeUrl && (
            <div>
              <h3>Scan the QR code</h3>
              <img
                src={qrCodeUrl}
                alt="QR code"
                style={{ width: "96px", height: "96px" }}
              />
            </div>
          )}
        </>
      )}
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default withAuthCheck(AdvanceOpinionGroupPoll);
