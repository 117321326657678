/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 *
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized
 * access, use, or distribution outside the official repositories of AIPolls.Net is
 * strictly prohibited.
 *
 * The founding team and any future employees or contractors are bound by these rules.
 *
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { refreshToken } from "../auth/auth.js"; // Assuming refreshToken is implemented to handle token refresh
import withAuthCheck from "../hoc/withAuthCheck";
import Sidebar from "../utils/Sidebar.js";
import logoutIcon from "../logout_icon.png";
import profileIcon from "../profile_icon.png";
import { logoText } from "../utils/utils.js";

const CreateTriviaPoll = () => {
  const [loadingImproved, setLoadingImproved] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", ""]); // Start with two options
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [expiryHours, setExpiryHours] = useState(4); // State for expiry hours
  const [pollId, setPollId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [popupMessage, setPopupMessage] = useState(""); // State for popup message

  // Function to add more options
  const addOption = () => {
    setOptions([...options, ""]);
  };

  // Logout handler needs to be defined at the top level
  const handleLogout = async () => {
    try {
      await api.post("/logout"); // Call your backend logout endpoint
      localStorage.removeItem("access_token"); // Clear the access token from localStorage
      navigate("/login"); // Redirect to login after logout
    } catch (error) {
      handleErrors(error);
    }
  };

  // Function to check if at least one field is filled
  const isAtLeastOneFieldFilled = () => {
    return (
      title.trim() !== "" || description.trim() !== "" || question.trim() !== ""
    );
  };

  const handleProfile = () => {
    // Navigate back to the profile
    navigate("/profile");
  };

  // Function to delete an option
  const deleteOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const areOptionsUnique = () => {
    const texts = options.map((option) => option.choice_text.trim());
    const uniqueTexts = new Set(texts);
    return uniqueTexts.size === texts.length;
  };

  // Function to create a new poll
  const handleCreatePoll = async (e) => {
    if (e) e.preventDefault();

    if (!areOptionsUnique()) {
      setErrorMessage("All options must be unique."); // Display error
      return;
    }

    try {
      const response = await api.post("/create_trivia_poll", {
        question,
        options,
        correct_answer: correctAnswer,
        expiryHours: parseInt(expiryHours, 10), // Send expiryHours as an integer
      });

      setPollId(response.data.poll_id);
      setErrorMessage("");
      navigate("/triviaPolls"); // Navigate to list after poll creation
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleExpiryChange = (e) => {
    const value = e.target.value;
    // Allow empty input or numbers only
    if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) <= 8760)) {
      setExpiryHours(value);
    }
  };

  const handleExpiryBlur = () => {
    // Enforce minimum value of 1 and maximum of 8760 on blur
    if (expiryHours === "" || parseInt(expiryHours, 10) < 1) {
      setExpiryHours(1);
    } else if (parseInt(expiryHours, 10) > 8760) {
      setExpiryHours(8760);
    }
  };

  const handleErrors = async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            handleCreatePoll(); // Retry creating poll after refreshing token
          } else {
            setErrorMessage("Failed to refresh token. Please log in again.");
            navigate("/login");
          }
        } catch (refreshError) {
          setErrorMessage("Session expired. Please log in again.");
          navigate("/login");
        }
      } else {
        setErrorMessage(
          error.response.data.message || "Failed to create poll."
        );
      }
    } else {
      setErrorMessage("No response received from server.");
    }
  };

  const handleGenPoll = async () => {
    if (!isAtLeastOneFieldFilled()) {
      setPopupMessage(
        "Please enter at least one of title, description or question so we can help improve it."
      );
      setShowPopup(true); // Show the popup
      return;
    }

    setLoadingImproved(true); // Set loading state to true
    //setAutonomous(false);
    callGeneratePoll(false);
  };

  const handleGenAutoPoll = async () => {
    setLoadingNew(true); // Set loading state to true
    //setAutonomous(true);
    callGeneratePoll(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const callGeneratePoll = async (isAutonomous) => {
    //const autonomous = !(title || description || question);
    // Declare num_choices outside the block
    let num_choices = null;

    let t = null;
    let d = null;
    let q = null;
    num_choices = options.length;
    if (isAutonomous) {
      num_choices = Math.floor(Math.random() * (6 - 2 + 1)) + 2;
      console.log("num_choices = ", num_choices); // This will log a random number between 2 and 6
      t = title;
      d = description;
      q = question;
    }

    try {
      const response = await api.post("/gen_apoll", {
        poll_type: "Trivia",
        autonomous: isAutonomous,
        title: t,
        description: d,
        question: q,
        options,
        num_choices,
        expiryHours: parseInt(expiryHours, 10) || null,
      });

      // Log the entire response to verify structure
      console.log("Full Response:", response.data);

      // Access the `poll` object inside `response.data`
      const poll = response.data.poll;

      // Update state with the data from the poll object
      setTitle(poll.title);
      setDescription(poll.description);
      setQuestion(poll.question);
      setOptions(poll.options);
      const correctOption = poll.options.find((option) => option.is_correct);
      if (correctOption) {
        setCorrectAnswer(correctOption.choice_text);
      }
      setErrorMessage("");
    } catch (error) {
      handleErrors(error);
    } finally {
      if (isAutonomous) {
        setLoadingNew(false); // Reset loading state
      } else {
        setLoadingImproved(false); // Reset loading state
      }
    }
  };

  const handleCancel = () => {
    // Navigate back to the dashboard
    navigate("/dashboard");
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">{logoText}</div>
          <div className="auth-buttons">
            <button onClick={handleLogout}>
              <img
                alt="Logout"
                title="Logout"
                src={logoutIcon}
                style={{ width: "25px", height: "25px" }}
              />
            </button>
            <button onClick={handleProfile}>
              <img
                alt="Profile"
                title="Profile"
                src={profileIcon}
                style={{ width: "25px", height: "25px" }}
              />
            </button>
          </div>
        </div>

        <h2>Create a Poll</h2>
        <form onSubmit={handleCreatePoll}>
          <div>
            <label>
              Title:
              <input
                type="text"
                className="input-field"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength="254"
              />
            </label>
          </div>
          <div
            style={{ display: "flex", alignItems: "flex-start", gap: "8px" }}
          >
            <label style={{ alignSelf: "center" }}>Description:</label>
            <textarea
              className="input-field"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength="1023"
              rows="4"
              cols="40"
              wrap="soft"
            />
          </div>
          <div>
            <label>
              Question:
              <input
                type="text"
                className="input-field"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                style={{ width: "75%" }}
                maxLength="254"
                required
              />
            </label>
          </div>

          {options.map((option, index) => (
            <div key={index} className="poll-option-container">
              <label className="poll-option-label">Option {index + 1}:</label>
              <input
                type="text"
                className="input-field"
                value={option.choice_text || ""} // Use choice_text for display
                onChange={(e) => {
                  setOptions((prevOptions) => {
                    const newOptions = [...prevOptions];
                    newOptions[index] = {
                      ...newOptions[index],
                      choice_text: e.target.value,
                    }; // Update choice_text
                    return newOptions;
                  });
                }}
                required
              />
              <button
                type="button"
                className="remove-button"
                onClick={() => deleteOption(index)}
              >
                Remove
              </button>
            </div>
          ))}

          <button type="button" onClick={addOption}>
            Add Option
          </button>

          <div>
            <label>
              Correct Answer:
              <select
                value={correctAnswer}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                required
              >
                <option value="">Select correct answer</option>
                {options.map((option, index) => (
                  <option key={index} value={option.choice_text}>
                    {option.choice_text}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div>
            <label>
              Expiry (hours):
              <input
                type="number"
                value={expiryHours}
                onChange={handleExpiryChange}
                onBlur={handleExpiryBlur}
                placeholder="Enter expiry time in hours"
                required
              />
            </label>
          </div>

          <button
            type="button"
            onClick={handleGenPoll}
            disabled={loadingImproved}
          >
            {loadingImproved ? "Improving AI Poll..." : "AI Poll Improve"}
          </button>
          <button
            type="button"
            onClick={handleGenAutoPoll}
            disabled={loadingNew}
          >
            {loadingNew ? "Generating New Poll..." : "AI Poll New"}
          </button>
          <button type="submit">Create Poll</button>
          <button onClick={handleCancel} style={{ marginTop: "20px" }}>
            Cancel
          </button>
        </form>

        {pollId && <p>Poll created successfully! Poll ID: {pollId}</p>}
        {errorMessage && <p style={{ color: "black" }}>{errorMessage}</p>}

        {/* Popup Dialog */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup">
              <p>{popupMessage}</p>
              <button onClick={closePopup}>Ok</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withAuthCheck(CreateTriviaPoll);
