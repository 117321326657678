import React from "react";

const DisclosureAgreement = () => {
  const handleViewPDF = () => {
    window.open('/disclosure_agreement', '_blank');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1>Disclosure Agreement</h1>
      <p>Please review the disclosure agreement below.</p>
      <button onClick={handleViewPDF}>View Disclosure Agreement</button>
      {/* Alternatively, you can embed the PDF directly: */}
      {/* <iframe
        src="/api/disclosure_agreement"
        title="Disclosure Agreement"
        width="100%"
        height="600px"
        style={{ border: 'none', marginTop: '20px' }}
      /> */}
    </div>
  );
};

export default DisclosureAgreement;
