/*
 * Copyright © 2024 AIPolls.Net. All rights reserved.
 * 
 * This software is the confidential and proprietary information of AIPolls.Net.
 * Only authorized personnel may access, modify, or deploy this software. Unauthorized 
 * access, use, or distribution outside the official repositories of AIPolls.Net is 
 * strictly prohibited.
 * 
 * The founding team and any future employees or contractors are bound by these rules.
 * 
 * This software is provided "AS IS," without any warranty, express or implied.
 */

import React, { useState, useEffect } from 'react';
import api from '../api'; // Assuming you have set up Axios instance
import { useNavigate } from 'react-router-dom'; // Assuming you use this to navigate after login
import handleErrors from '../auth/handleErrors';
import { isWhitelisted } from '../utils/whitelist';
import Sidebar from '../utils/Sidebar.js';
import '../App.css';
import logoutIcon from '../logout_icon.png';
import profileIcon from '../profile_icon.png';
import slogan from '../aipolls_slogan.gif';
import { logoText } from '../utils/utils.js';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');  // Add state for QR code URL
  const [loginMode, setLoginMode] = useState(false);  // Add state for signup mode
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpRequired, setOtpRequired] = useState(false); // New state for OTP requirement
  const [title, setTitle] = useState('');
  const [gender, setGender] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [dob, setDob] = useState('');
  const navigate = useNavigate(); // To redirect after login
  const [acceptedAgreement, setAcceptedAgreement] = useState(false); // New state for agreement checkbox
  const [street, setStreet] = useState('');
  const [aptSuite, setAptSuite] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [company, setCompany] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  useEffect(() => {

    if (loginMode) {
      console.log('Redirecting to login page');
      navigate('/login'); // Redirect to signup page when signupMode is true
    }
    console.log('Signup mode is enabled');

    //const ALLOWED_IPS = [MANOJ_IP, TANISHA_IP];
    // Fetch IP address
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        console.log('IP Address:', data.ip);
        //setIp(data.ip); // Store IP address in state
        if (!isWhitelisted(data.ip)) {
          //if (!ALLOWED_IPS.includes(data.ip)) {
          console.error('You do not have permission to access this page.');
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, [loginMode, navigate]);

  // Helper function to check if a string contains control characters
  const containsControlCharacters = (password) => {
    const controlCharacterRegex = /[\p{C}]/u; // \p{C} matches control characters using Unicode properties
    return controlCharacterRegex.test(password);
  };

  // Function to validate the password
  const validatePassword = (password, email, name) => {
    const passwordRulesMessage = `
1. Does not start with a numeral
2. Must have at least 1 uppercase character
3. Must have at least 1 special character from the following: ('$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#')
4. Must not contain any space characters
5. Must not contain any control or non-printable characters
6. Must not contain your email address or name in any upper or lower case combinations
`;

    const specialChars = ['$', '@', '^', '-', '_', '%', '?', '&', '!', '*', '(', ')', '[', ']', '{', '}', '|', '~', '<', '>', ';', ':', '+', '#'];

    // Rule 1: Password must not start with a numeral
    if (/^\d/.test(password)) {
      return `Password does not meet the following requirements [1]: ${passwordRulesMessage}`;
    }

    // Rule 2: Must contain at least 1 uppercase letter
    if (!/[A-Z]/.test(password)) {
      return `Password does not meet the following requirements [2]: ${passwordRulesMessage}`;
    }

    // Rule 3: Must contain at least 1 special character
    if (!specialChars.some(char => password.includes(char))) {
      return `Password does not meet the following requirements [3]: ${passwordRulesMessage}`;
    }

    // Rule 4: Must not contain any space characters
    if (/\s/.test(password)) {
      return `Password does not meet the following requirements [4]: ${passwordRulesMessage}`;
    }

    // Rule 5: Must not contain any control or non-printable characters
    if (containsControlCharacters(password)) {
      return `Password does not meet the following requirements [5]: ${passwordRulesMessage}`;
    }

    // Rule 6: Must not contain the email or name (case-insensitive)
    const emailLower = email.toLowerCase();
    const nameLower = name.toLowerCase();
    const passwordLower = password.toLowerCase();

    if ((emailLower && passwordLower.includes(emailLower)) || (nameLower && passwordLower.includes(nameLower))) {
      return `Password does not meet the following requirements [6]: ${passwordRulesMessage}`;
    }

    return null; // Password is valid
  };

  // Handle user signup
  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if passwords match before submitting
    if (password !== confirmPassword) {
      setSuccessMessage('');
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Validate password
    const validationError = validatePassword(password, email, name);
    if (validationError) {
      setSuccessMessage('');
      setErrorMessage(validationError);
      return;
    }

    // Ensure the user has accepted the agreement
    if (!acceptedAgreement) {
      setSuccessMessage('');
      setErrorMessage('You must accept the disclosure agreement before signing up.');
      return;
    }

    console.log('Submitting signup form...');

    try {
      //const response = await api.post('https://192.168.1.188/api/signup', {
      const response = await api.post('/signup', {
        name,
        email,
        password,
        otp_required: otpRequired,
        street,
        apt_suite: aptSuite,
        city,
        state,
        zip_code: zipCode,
        country,
        mobile_phone: mobilePhone,
        work_phone: workPhone,
        company,
        title: title,
        gender: gender,
        pronouns: pronouns,
        dob: dob,
        job_title: jobTitle,
        accepted_agreement: acceptedAgreement
      }, {
        withCredentials: true  // Include cookies with the request
      });

      if (response === null) {
        throw new Error('Response is null');
      } else {
        console.log(response);
        if (response.status === 200) {
          console.log('Signup successful');
          setSuccessMessage('Signup successful.');
          // Redirect to login page after successful signup
          // The response should return the QR code as an image URL
          const qrCodeURL = response.data.qr_code_image;  // This contains the base64-encoded image
          setQrCodeUrl(qrCodeURL);  // Set the state with the base64-encoded QR code
          setErrorMessage('');
          !otpRequired && navigate('/login'); // Redirect to login page after successful signup
        } else {
          console.log('Signup failed');
          setSuccessMessage('');
          setErrorMessage('Signup failed. Please try again.');
        }
      }

    } catch (error) {
      handleErrors(error, navigate, null, setErrorMessage);
    }
  };

  return (
    <div className="container">
      {/* Sidebar Menu */}
      <Sidebar />
      {/* Main content */}
      <div className="main-content">
        <div className="header">
          <div className="logo">{logoText}</div>
          <div className="auth-buttons">
            {(
              <button>
                <img alt="Logout" title="Logout" src={logoutIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
            {(
              <button>
                <img alt="Profile" title="Profile" src={profileIcon} style={{ width: '25px', height: '25px' }} />
              </button>
            )}
          </div>
        </div>


        <h2>Signup</h2>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'black' }}>{successMessage}</p>}
        <form onSubmit={handleSignup} style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px' }}>

          {/* Email Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="email" style={{ marginRight: '10px' }}>
              Email <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Password Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="password" style={{ marginRight: '10px' }}>
              Password <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Confirm Password Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="confirmPassword" style={{ marginRight: '10px' }}>
              Confirm Password <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Re-enter Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{ padding: '8px' }}
            />
          </div>

          {/* Checkbox to enable/disable OTP input */}
          <label style={{ display: 'block', marginBottom: '10px' }}>
            <input
              type="checkbox"
              checked={otpRequired}
              onChange={(e) => setOtpRequired(e.target.checked)}
            />
            Enable OTP for additional security
          </label>

          {/* Display QR code for Google Authenticator setup */}
          {otpRequired && qrCodeUrl && (
            <div>
              <h3>Scan the QR code with Google Authenticator and click on:</h3>
              <a href="/login">this link to sign in</a>
              {/*<img src={qrCodeUrl} alt="QR code for Google Authenticator" />*/}
              <img
                src={qrCodeUrl}
                alt="QR code for Google Authenticator"
                style={{ width: '96px', height: '96px' }} // 1 inch by 1 inch
              />
            </div>
          )}

          {/* Checkbox to accept the agreement */}
          <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={acceptedAgreement}
              onChange={(e) => setAcceptedAgreement(e.target.checked)}
              id="accept-agreement"
              style={{ marginRight: '10px' }}
            />
            <label htmlFor="accept-agreement">
              I accept the <a href={`/api/disclosure_agreement`} target="_blank" rel="noopener noreferrer">disclosure agreement</a>
            </label>
          </div>

          <button type="submit" style={{ padding: '10px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px' }}>Signup</button>

          <p />
          <button onClick={() => setLoginMode(true)}>Already have an account? Login</button>

          {/* Optional Section Divider */}
          <hr style={{ border: '0', height: '1px', backgroundColor: '#ddd', marginBottom: '20px' }} />

          {/* Subtitle for optional fields */}
          <h3 style={{ fontSize: '18px', marginBottom: '10px', color: '#555' }}>Additional Information (Optional)</h3>

          <div style={{ marginBottom: '15px' }}>
            <label>
              Title:
              <select
                onChange={(e) => setTitle(e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Select...</option>
                <option value="Mr">Mr.</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms.</option>
                <option value="Mrs">Mrs.</option>
                <option value="Sir">Sir</option>
                <option value="Dr">Dr.</option>
                <option value="Prof">Prof.</option>
                <option value="Rev">Rev.</option>
                <option value="Hon">Hon.</option>
                <option value="N/A">N/A</option>
              </select>
            </label>
          </div>

          {/* Name Field */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="name" style={{ marginRight: '10px' }}>
              Name {/*<span style={{ color: 'red' }}>*</span>*/}
            </label>
            <input
              type="text"
              id="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          {/* Gender Field */}
          <div style={{ marginBottom: '15px' }}>
            <label>
              Gender:
              <select
                onChange={(e) => setGender(e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Select...</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
                <option value="Prefer not to say">Prefer not to say</option>
              </select>
            </label>
          </div>

          {/* Pronoun Field */}
          <div style={{ marginBottom: '15px' }}>
              <label>
                Pronouns:
                <select
                  onChange={(e) => setPronouns(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Select...</option>
                  <option value="He/Him">He/Him</option>
                  <option value="She/Her">She/Her</option>
                  <option value="They/Them">They/Them</option>
                  <option value="Ze/Hir">Ze/Hir</option>
                  <option value="Ze/Zir">Ze/Zir</option>
                  <option value="Xe/Xem">Xe/Xem</option>
                  <option value="Ve/Ver">Ve/Ver</option>
                  <option value="Other">Other</option>
                  <option value="N/A">N/A</option>
                </select>
              </label>
            </div>

          {/* Address Details */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="street" style={{ marginRight: '10px' }}>Street</label>
            <input
              type="text"
              id="street"
              placeholder="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="aptSuite" style={{ marginRight: '10px' }}>Apt/Suite</label>
            <input
              type="text"
              id="aptSuite"
              placeholder="Apt/Suite"
              value={aptSuite}
              onChange={(e) => setAptSuite(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="city" style={{ marginRight: '10px' }}>City</label>
            <input
              type="text"
              id="city"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="state" style={{ marginRight: '10px' }}>State</label>
            <input
              type="text"
              id="state"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="zipCode" style={{ marginRight: '10px' }}>
              Zip Code {/*<span style={{ color: 'red' }}>*</span>*/}
            </label>
            <input
              type="text"
              id="zipCode"
              placeholder="Zip Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="country" style={{ marginRight: '10px' }}>Country</label>
            <input
              type="text"
              id="country"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          {/* Contact Details */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="mobilePhone" style={{ marginRight: '10px' }}>
              Mobile Phone {/*<span style={{ color: 'red' }}>*</span>*/}
            </label>
            <input
              type="text"
              id="mobilePhone"
              placeholder="Mobile Phone"
              value={mobilePhone}
              onChange={(e) => setMobilePhone(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="workPhone" style={{ marginRight: '10px' }}>Work Phone</label>
            <input
              type="text"
              id="workPhone"
              placeholder="Work Phone"
              value={workPhone}
              onChange={(e) => setWorkPhone(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          {/* Professional Details */}
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="company" style={{ marginRight: '10px' }}>Company</label>
            <input
              type="text"
              id="company"
              placeholder="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="jobTitle" style={{ marginRight: '10px' }}>Job Title</label>
            <input
              type="text"
              id="jobTitle"
              placeholder="Job Title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              style={{ padding: '8px' }}
            />
          </div>



          <button type="submit" style={{ padding: '10px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px' }}>Signup</button>
        </form>



        <p />
        <button onClick={() => setLoginMode(true)}>Already have an account? Login</button>
        {/* Display animated GIF below the form */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <img
            src={slogan}
            alt="Signup Slogan"
            style={{ width: '300px', height: 'auto' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
